<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 px-0">
      <dx-data-grid
          id="costGroupGrid"
          ref="costGroupGridRef"
          :height="setHeight"
          :data-source="CostGroupDataSource"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :hover-state-enabled="true"
          :row-alternation-enabled="false"
          @option-changed="onOptionChanged"
          @initialized="onInitialized"
          @content-ready="onContentReady"
          @exporting="onExporting"
          @toolbar-preparing="onToolbarPreparing($event)"
          @row-removed="rowAffected"
          @row-inserted="rowAffected"
        >
          <dx-sorting mode="single" />
          <dx-remote-operations :paging="true" :sorting="false" :filtering="false" />
          <dx-search-panel
            :highlight-search-text="true"
            :search-visible-columns-only="true"
            :visible="true"
            :width="200"
            placeholder="Search..."
          />
          <!--region    ::DataGird actions -->
          <dx-column data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="140" :fixed="true" fixed-position="left" />
          <template #itemActions="{data}">
            <div>
              <dx-util-button
                type="success" icon="icon icon-psh-logistic-3"
                class="mr-half"
                hint="Go to material items of this group."
                @click="onClickGoTo(data)"
              />
              <dx-util-button
                type="warning" icon="bi bi-pencil-square"
                class="mr-half"
                hint="Edit Cost Group"
                @click="onClickCreateEdit(data)"
              />
              <dx-util-button
                type="danger" icon="bi bi-trash"
                hint="Delete this sorted item"
                @click="onClickDelete(data)"
              />
            </div>
          </template>
          <!--endregion ::DataGird actions -->
          <dx-column data-field="name" width="auto" />
          <dx-column data-field="inventoryType" :width="130" cell-template="inventoryTypeCell" />
          <template #inventoryTypeCell="{data}">
            <div :class="resolveInventoryTypeFormat(data.value)" class="d-block">
              {{ resolveInventoryType(data.value) }}
            </div>
          </template>
          <dx-column data-field="notes" width="75%" />
          <dx-paging :page-size="pagerOptions.pageSize" />
          <dx-pager
            :allowed-page-sizes="pagerOptions.allowedPageSizes"
            :show-info="pagerOptions.showInfo"
            :show-navigation-buttons="pagerOptions.showNavigationButtons"
            :show-page-size-selector="pagerOptions.showPageSizeSelector"
            :visible="pagerOptions.visible"
          />
        </dx-data-grid>
      </div>
        <!--Begin:: Create Or Update Form-->

      <div class="col-md-12 px-0">
        <dx-util-popup
          id="costGroupPopup"
          ref="costGroupPopupRef"
          :show-close-button="false"
          :drag-enabled="false"
          :close-on-outside-click="false"
          :show-title="true"
          :width="720"
          :height="560"
          :title="popupTitle"
          @hidden="onHiddenCostGroup"
        >
          <dx-util-position at="center" my="center" />
          <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelButtonOptions" />
          <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="saveButtonOptions" />
          <form @submit="handleSubmit">
            <dx-util-form ref="costGroupFormRef" :form-data="costGroupForm" :col-count="1" :show-colon-after-label="true" label-location="top">
                <dx-util-item
                  data-field="name"
                  :is-required="true"
                  editor-type="dxTextBox"
                  :label="{text: 'Cost Group Name'}"
                >
                  <dx-util-required-rule message="Cost Group Name is required" />
                </dx-util-item>
                <dx-util-item data-field="notes" :is-required="true" editor-type="dxTextArea" :editor-options="notesOptions" :label="{text: 'Notes'}">
                  <dx-util-required-rule message="notes is required" />
                </dx-util-item>
                <dx-util-item data-field="inventoryType" editor-type="dxRadioGroup" :editor-options="inventoryTypeOptions" :label="{text: 'Inventory Type'}">
                  <dx-util-required-rule message="inventory type is required" />
                </dx-util-item>
            </dx-util-form>
          </form>
        </dx-util-popup>
      </div>
        <!--End:: Create Or Update Form-->

    </div>
  </div>
</template>

<script>
import GridBase from '@core/dev-extreme/mixins/grid/base'
import useConfirmation from '@/libs/app/confirmation'
import { Notify } from '@robustshell/utils/index'
import costGroupService from '@/http/requests/finance/costGroupService'
import { getInventoryTypeList } from '@/enums/inventoryType.enum'
import { CostGroupDataSource } from './costGroupStore'

export default {
  /* eslint-disable no-unused-vars */
  mixins: [GridBase],
  setup() {
    const { pshConfirm } = useConfirmation()
    return { pshConfirm }
  },
  data: () => ({
    CostGroupDataSource,
    popupTitle: 'Create New Cost Group Record',
    costGroupForm: {
      id: null,
      name: '',
      notes: '',
      inventoryType: null,
    },
    inventoryTypes: getInventoryTypeList(),
  }),
  computed: {
    dataGrid() {
      const gridIns = this.$refs.costGroupGridRef.instance
      return gridIns
    },
    costGroupPopup() {
      const popupIns = this.$refs.costGroupPopupRef.instance
      return popupIns
    },
    saveButtonOptions() {
      return {
        text: 'Save',
        type: 'success',
        useSubmitBehavior: true,
        onClick: e => {
          this.handleSubmit(e.event)
        },
      }
    },
    cancelButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.costGroupPopup.hide()
        },
      }
    },
    notesOptions() {
      return {
        minHeight: '140px',
      }
    },
    inventoryTypeOptions() {
      return {
        dataSource: this.inventoryTypes,
        displayExpr: 'text',
        valueExpr: 'value',
        layout: 'horizontal',
        value: this.costGroupForm.inventoryType,
      }
    },
  },
  methods: {
    onOptionChanged(e) {
      if (e.fullName === 'paging.pageSize') {
        CostGroupDataSource.pageIndex(0)
        CostGroupDataSource.reload()
      }
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      const tempToolbarItems = [...toolbarItems]
      toolbarItems.splice(0, toolbarItems.length)
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          text: 'Prep Materials',
          icon: 'icon icon-psh-logistic-3',
          type: 'success',
          onClick: () => {
            this.$router.push({ name: 'route-material-items' })
          },
        },
        location: 'after',
      })
      tempToolbarItems.forEach(item => {
        toolbarItems.push(item)
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'plus',
          hint: 'Create New',
          onClick: () => {
            this.onClickCreateEdit()
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            CostGroupDataSource.reload()
          },
        },
        location: 'after',
      })
    },
    initialLoad() {
      CostGroupDataSource.load()
    },
    onClickCreateEdit(e) {
      const id = e ? e.row.data.id : undefined
      if (id !== undefined && id !== null && id !== '' && id !== 0) {
        CostGroupDataSource.store().byKey(id).then(result => {
          this.costGroupForm = {
            id: result.id,
            name: result.name,
            notes: result.notes,
            inventoryType: result.inventoryType,
          }
        }).then(() => {
          this.costGroupPopup.show()
          CostGroupDataSource.reload()
        })
      } else {
        this.costGroupPopup.show()
      }
    },
    async onClickDelete(e) {
      const confirm = await this.pshConfirm(
        'Delete Confirmation',
        `<p>Are you sure you want to delete this cost group record?</p>
         <h4 class="text-danger mt-half">This action cannot be undone.</h4>`,
      )
      if (confirm) {
        try {
          const result = await costGroupService.deleteById(e.row.data.id)
          if (result) {
            CostGroupDataSource.reload()
          }
        } catch (error) {
          Notify.error(error)
        }
      }
    },
    onClickGoTo(e) {
      const costGroupId = e.data.id
      this.$router.push({ name: 'route-material-items', query: { qCostGroupId: costGroupId } })
    },
    async handleSubmit(e) {
      const form = this.$refs.costGroupFormRef.instance
      const formValidate = form.validate()
      if (formValidate.isValid) {
        const id = this.costGroupForm.id
        const data = {
          id: this.costGroupForm.id,
          name: this.costGroupForm.name,
          notes: this.costGroupForm.notes,
          inventoryType: this.costGroupForm.inventoryType,
        }
        if (this.costGroupForm.id > 0) {
          await costGroupService.update(data)
        } else {
          await costGroupService.create(data)
        }
        this.costGroupPopup.hide()
        CostGroupDataSource.reload()
      }
    },
    rowAffected(e) {
      CostGroupDataSource.reload()
    },
    resolveInventoryTypeFormat(item) {
      if (item === 'NONE_INVENTORY') return 'badge badge-light-success'
      if (item === 'INVENTORY') return 'badge badge-light-primary'
      return ''
    },
    resolveInventoryType(item) {
      if (item === 'NONE_INVENTORY') return 'NON-INVENTORY'
      if (item === 'INVENTORY') return 'INVENTORY'
      return ''
    },
    onHiddenCostGroup() {
      this.costGroupForm.id = null
      this.costGroupForm.name = ''
      this.costGroupForm.notes = ''
      this.costGroupForm.inventoryType = null
    },
  },
}
</script>

<style lang="scss">
</style>
