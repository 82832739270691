/* eslint-disable no-unused-vars */
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import costGroupService from '@/http/requests/finance/costGroupService'
import Pager from '@core/dev-extreme/utils/pager'

const store = new CustomStore({
  key: 'id',
  cacheRawData: false,
  load: async loadOptions => {
    const options = {}
    const sort = (loadOptions.sort && loadOptions.sort.length > 0) ? loadOptions.sort : [{ selector: 'id', desc: false }]
    const pager = new Pager(options, sort)
    pager.setPageNumber(loadOptions.skip, loadOptions.take)
    pager.setSortOrder(sort)
    const pageableQuery = `${pager.toCreatePageable()}`

    const response = await costGroupService.fetchAll(pageableQuery)
    const data = response.data
    return {
      data: data.content,
      totalCount: data.totalElements,
    }
  },
  byKey: key => costGroupService
    .fetchById(key).then(response => {
      const data = response.data.body
      return data
    }),
  insert: values => {
    costGroupService.create(values)
  },
  update: (key, values) => {
    const data = { id: key, ...values }
    costGroupService.update(data)
  },
  remove: key => {
    costGroupService.deleteById(key)
  },
})

const costGroupDataSource = new DataSource({
  store: store,
})

export {
  costGroupDataSource as CostGroupDataSource,
}
